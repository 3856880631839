<template>
  <div
    class="group"
    :id="item.name"
    v-if="item.submenu">
    <li
      class="mb-3 px-4 py-4 grid text-black rounded rounded-lg text-center justify-center cursor-pointer"
      :class="[
                  {'bg-temp3-success text-white hover:text-white': checkGrpChildrenActive(item)},
                  {'bg-gray-100 text-gray-600 hover:text-temp3-success': !checkGrpChildrenActive(item)},
                ]">
                <span class="mx-auto pb-2" v-if="item.svg">
                  <inline-svg  :src="require(`@/assets/svgs/template3/navbar/${item.svg}`)"/>
                </span>
      <p class="font-bold menu-title-hover">
        <span >{{ $t(item.i18n) || item.name }}</span>
      </p>
    </li>
    <div
      :id="item.name + '-submenu'"
      class="mb-2 bg-gray-800 px-1 py-1 rounded-lg"
      :class="[
        {'block menu-title-hover': checkGrpChildrenActive(item)},
        {'hidden group-hover:block': !checkGrpChildrenActive(item)}
      ]">
      <div
        v-for="(subItem, index) in item.submenu"
        class="">

        <router-link
          :to="subItem.url">
          <li
            class="mx-1 my-2 px-4 py-4 grid text-black rounded rounded-lg text-center justify-center cursor-pointer"
            :class="[
                        {'bg-temp3-success text-white hover:text-white': activeLink(subItem)},
                        {'bg-gray-100 text-gray-600 hover:text-temp3-success': !activeLink(subItem)},
                      ]">
                    <span class="mx-auto pb-2" v-if="subItem.svg">
                      <inline-svg :src="require(`@/assets/svgs/template3/navbar/${subItem.svg}`)"/>
                    </span>
            <p class="font-bold">
              <span >{{ $t(subItem.i18n) || subItem.name }}</span>
            </p>
          </li>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg';

export default {
name: "SubMenuItem",
  components: {
    InlineSvg,
  },
  props: {
    item: {
      default: {},
      type: Object,
    }
  },
  methods: {
    activeLink(item) {
      // console.log(this.$route.meta.parent)
      return !!((item.url === this.$route.path || this.$route.meta.parent === item.slug) && item.url)
    },
    checkGrpChildrenActive(group) {
      const path = this.$route.fullPath
      let active = false
      const routeParent = this.$route.meta ? this.$route.meta.parent : undefined

      if (group.submenu) {
        group.submenu.forEach((item) => {
          if (active) return true
          if ((path === item.url || routeParent === item.slug) && item.url) { active = true } else if (item.submenu) { this.checkGrpChildrenActive(item) }
        })
      }

      return active
    },
    isElementVisible(element) {
      return !!(element.offsetWidth ||
        element.offsetHeight ||
        element.getClientRects().length);
    }
},
  mounted() {
  setTimeout(() => {
    // Scroll on Hover
    document.getElementById(this.item.name).addEventListener('mouseover', (e) => {
      setTimeout(() => {
        let v = document.getElementById(this.item.name + '-submenu')
        let rec = v.getBoundingClientRect();
        let s = rec.top >= 0 &&
          rec.left >= 0 &&
          rec.top + 100 <= (window.innerHeight || document.documentElement.clientHeight) &&
          rec.right <= (window.innerWidth || document.documentElement.clientWidth);
        if(!s) {
          let n = document.getElementsByTagName('nav')[0]
          n.scroll({
            top: rec.top - 100,
            left: 100,
            behavior: 'smooth'
          });
        }
      }, 200)
    })
  }, 2000)
  }
}
</script>

<style scoped>

</style>
